
    import { defineComponent } from 'vue';
    import MediaPostContent from '@/components/MediaPostContent.vue';
    import Pagination from '@/components/Pagination.vue';
    import Loader from '@/components/Loader.vue';
    import Breadcrumbs from '@/components/Breadcrumbs.vue';

    export default defineComponent({
        name: 'Media',
        components: {
            MediaPostContent, Pagination, Loader, Breadcrumbs,
        },

        data() {
            return {
                total: 0,
                posts: [],
                page: '1',
                loaded: false,
                breadcrumbs: [
                    {
                        name: 'Media',
                        url: 'media',
                    }
                ],
            }
        },

        created() {
            this.$watch(
                () => this.$route.params.page,
                async () => {
                    if (this.$route.name === 'media') {
                        await this.fetchData();
                        document.title = `Media - Page ${this.page} | virtualwolf.xyz`
                    }
                },
                {
                    immediate: true,
                },
            )
        },

        computed: {
            generatedBreadcrumbs() {
                return [
                    {
                        name: 'Media',
                        url: 'media'
                    },
                    {
                        name: `Page ${this.$route.params.page || '1'}`,
                        url: this.$route.params.page || '1',
                    },
                ]
            },
        },

        methods: {
            async fetchData() {
                this.loaded = false;

                this.page = this.$route.params.page && typeof this.$route.params.page === 'string'
                    ? this.$route.params.page
                    : '1';

                const response = await fetch(`https://virtualwolf.org/rest/media?page=${this.page}`);
                const data = await response.json();

                this.total = data.total;
                this.posts = data.posts;
                this.loaded = true;
            }
        }
    });
